import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Modal } from 'semantic-ui-react';

import Text from '../../components/Text';
import Flex from '../../components/Flex';
import FlexItem from '../../components/FlexItem';

import PenggunaForm from '../Pengguna/Form';

import { pengguna } from '../../fetcher';
import { UserContext } from '../../context';
import { compose, withHandlers, withState, lifecycle, withProps } from 'recompose';

const IconCstm = styled(Icon)`
	:hover {
		cursor: pointer;
	}
`

const Header = ({ is_expand, setExpand, user, error, dirty, loading, onLogout, toggleModal, open, handleChange, handleClick }) => {
	return <Flex style={{ width: '100%' }} alignItems="center" >
		<FlexItem style={{ paddingRight: '.4em' }}><IconCstm color="grey" size="large" name={is_expand ? 'compress' : 'expand'} onClick={() => setExpand(!is_expand) }/></FlexItem>
		<FlexItem grow={1}>
			<Text padding=".2em" display="inline" color="#EE2F5A" fontWeight="600" fontFamily="'Montserrat', sans" fontSize="22pt" letterSpacing=".6px">Alaia</Text>
			<Text paddingLeft=".2em" color="#6D4D88d9" display="inline" fontSize="20pt">Skin Care</Text>
		</FlexItem>
		<FlexItem style={{ padding: '0 1em' }}>
			<Modal centered={false} trigger={<Button color="blue" compact size="tiny" onClick={toggleModal} icon><Icon name="user" style={{ paddingRight: "1.2em" }}/> {_.get(user, 'nama', '-')}</Button>} open={open} onClose={null} >
				<Modal.Header><Text textAlign="center" fontSize="1.2em">Data Pengguna</Text></Modal.Header>
				<Modal.Content> <PenggunaForm {..._.assign({ user: dirty, loading, error, handleChange, handleClick })}/> </Modal.Content>
			</Modal>
		</FlexItem>
		<FlexItem><Button color="pink" compact size="tiny" onClick={onLogout} icon><Icon name="log out" /> Logout </Button></FlexItem>
	</Flex>;
};

const HeaderComp = compose(
	withState('dirty', 'setDirty', {}),
	withState('error', 'setError', {}),
	withState('open', 'setOpen', false),
	withState('loading', 'setLoading', false),
	withProps({
		validate: (form_value) => {
			const error = { list: [] };
			const required	= 'nama panggilan username'.split(' ');
			
			_.mapValues(form_value, (v, k) => {
				if (_.includes(required, k)) {
					const temp = _.get(form_value, k, '').toString();
					if (_.isEmpty(temp)) { error.list.push(`Isian '${k}' tidak boleh kosong`); }
				}
			});

			if (!_.isEmpty(form_value.password)) {
				if (_.isEmpty(form_value.new_password) || !_.isEqual(form_value.new_password, form_value.re_password)) {
					error.list.push(`Isian 'New Password' dan 'Re type Password' harus sama dan tidak boleh kosong`);
				}
			}

			if (!_.isEmpty(error.list)) { error.header = 'Beberapa nilai pada isian form tidak valid'; }
			return error;
		}
	}),
	withHandlers({
		toggleModal: (props) => () => { props.setOpen(!props.open); },
	}),
	withHandlers({
		onLogout: (props) => async () => {
			await pengguna.logout();
			return props.checkStatus();
		},
		handleClick: (props) => async (e, data) => {
			switch(data.name) {
				case "save": {
					props.setLoading(true);
					const body 		= _.pickBy(props.dirty, (v, k) => !_.isEqual(v, props.user[k]));
					const retval	= await pengguna.update(props.dirty._id, body);
					if (retval.response !== 'OK') {
						props.setError({ header: 'Beberapa nilai pada isian form tidak valid', list: [retval.message] });
					}
					return props.setLoading(false);
				}
				default:
					props.toggleModal();
			}
		},
		handleChange: (props) => (e, { name, value }) => {
			const new_value = _.cloneDeep(props.dirty);
			_.set(new_value, name, value);

			props.setDirty(new_value);
			props.setError(props.validate(new_value));
		}
	}),
	lifecycle({
		componentDidMount(){ this.props.setDirty(this.props.user); }
	})
)(Header);

export default (props) => <UserContext.Consumer>{(user) => {
	return <HeaderComp {...props} user={user.data} checkStatus={user.checkStatus} />
}}</UserContext.Consumer>